export default function SteppedProgressBar({ step1, step2, step3, title, step1Text='Step 1', step2Text='Step 2', step3Text='Step 3' }) {
  if(step2 && step1 && !step3){
    if(step3Text==='Step 3'){
        step3Text = "Almost done!";
    }
  } else if(step2 && step1 && step3){
      //step3Text = 'Step 3:';
  }
        
  return (
    <div className="stepped-progress-bar-wrapper">
      <div className="progress-bar-bg">
        <div className={step1 ? 'step step1 clear' : 'step step1'}></div>
        <div className={step2 ? 'step step2 clear' : 'step step2'}></div>
        <div className={step3 ? 'step step3 clear' : 'step step3'}></div>
      </div>
      <div className="subtext">
        <div className="text-step">{step1Text}</div>
        <div className="text-step">{step2Text}</div>
        <div className="text-step">{step3Text}</div>
      </div>
    </div>
  );
}
