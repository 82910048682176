import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { openBase64NewTab } from '../../utils/helper';
import useTacfsService from '../../utils/tacfs/useTacfsService';

export const ReceiptLink = ({ purchase }) => {
  const { load } = useTacfsService();
  const [receiptDownload, setReceiptDownload] = useState(false);

  const { isLoading, data: receipt } = useQuery(
    [`${purchase.date_of_purchase}receipt`],
    () => load('receipt', { postedDate: purchase.date_of_purchase }),
    { enabled: receiptDownload },
  );
  
  useEffect(() => {
    if (
      isLoading === false &&
      receipt?.pdf_data &&
      receiptDownload 
    ) {
      setReceiptDownload(false);
      openBase64NewTab(
        decodeURIComponent(receipt.pdf_data),
        `receipt_${purchase.date_of_purchase}.pdf`,
      );
    }
  }, [receipt, receiptDownload, isLoading, purchase]);

  return (
        <div className="due-date">
          {isLoading ? (
              <button className="reflexblue underline-link disabled" disabled>
                Downloading...
              </button>
            ) : (
              <button className="reflexblue underline-link" onClick={() => setReceiptDownload(true)}>
                Download Receipt
              </button>
            )}
        </div>
  );
};

