import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

import ReadStorage from '../components/storage/ReadStorage';
import useTacfsService from '../utils/tacfs/useTacfsService';

import { Layout } from '../components/Layout';
import { Breadcrumbs } from '../components/global/Breadcrumbs';
import { AuthDetails } from '../components/login/AuthDetails';
import { ProcessMessages } from '../components/messageCenter/ProcessPortalMessages';
import { KnowledgeHubPlus } from '../components/dashboard/KnowledgeHubPlus';
import {
  getRecertStatusRevised,
  compliantStatuses,
} from './DesignationsCertifications';

import {
  LearningJourneyModule,
  LearningJourneyNoEnrollment,
  LearningJourneyJIT,
} from '../components/dashboard/LearningJourneyModule';
import {
  DesignationsModule,
  DesignationsModuleNone,
} from '../components/dashboard/DesignationsModule';
import { StaticSavedResources } from '../components/resources/StaticSavedResources';
import { PromotionalCalloutModule } from '../components/dashboard/PromotionalCalloutModule';
import { QuickLinksModule } from '../components/dashboard/QuickLinksModule';
import Timer from '../components/subcomponents/Timer';

export function Dashboard() {
  const oktaProfile = ReadStorage('oktaProfile');
  const { load } = useTacfsService();

  const [designations, setDesignations] = useState([]);
  const [completedDesignations, setCompletedDesignations] = useState([]);
  const [jitDesignations, setJitDesignations] = useState([]);
  const [recertStatus, setRecertStatus] = useState(false);
  const [inGoodStanding, setInGoodStanding] = useState(false);

  const { isLoading: courseHistoryLoading, data: courseHistory } = useQuery(
    ['courseHistory'],
    () => load('courseHistory'),
  );
  const { isLoading: transcriptHistoryLoading, data: transcriptHistory } =
    useQuery(['transcriptHistory'], () => load('transcriptHistory'));
  const { isLoading: studentCELoading, data: studentCE } = useQuery(
    ['studentCE'],
    () => load('studentCE'),
    {
      enabled: oktaProfile?.profile?.studentID != null,
    },
  );
  const { isLoading: isLoadingFlags, data: studentFlags } = useQuery(
    ['studentFlags'],
    () => load('studentFlags'),
    {
      enabled: oktaProfile?.profile?.studentID != null,
    },
  );

  useEffect(() => {
    if (!studentCELoading && studentCE) {
      const recert = getRecertStatusRevised(studentCE);
      setRecertStatus(recert);
      console.log(`recert: ${recert}`);
      setInGoodStanding(compliantStatuses.includes(recert));
      console.log('In good standing?', compliantStatuses.includes(recert));
    }
  }, [studentCELoading, studentCE, getRecertStatusRevised, setInGoodStanding]);

  useEffect(() => {
    if (!courseHistoryLoading && courseHistory) {
      const jitLearning = ['CE', 'SUBS', 'SACS'];
      let tempDes = [],
        tempComplete = [],
        tempJit = [];

      for (let i = 0; i < courseHistory.length; i++) {
        const acadPlan = courseHistory[i].academic_plan;
        const status = courseHistory[i]?.status;

        // BRIGHTSPACE designations go in completedDesignations
        // JIT Learning designations go in jitDesignations
        // Anything that is not JIT Learning or completed goes in designations
        // Each designation appears at most once in the list, and in only one list
        if (status === 'BRIGHTSPACE' && !tempComplete.includes(acadPlan)) {
          tempComplete.push(acadPlan);
        } else if (
          jitLearning.includes(acadPlan) &&
          !tempJit.includes(acadPlan)
        ) {
          tempJit.push(acadPlan);
        } else if (
          acadPlan !== 'NOTAPPL' &&
          !tempDes.includes(acadPlan) &&
          !tempJit.includes(acadPlan)
        ) {
          tempDes.push(acadPlan);
        }
      }

      setDesignations(tempDes);
      setJitDesignations(tempJit);
      setCompletedDesignations(tempComplete);
    }
  }, [courseHistoryLoading, courseHistory]);

  return (
    <Layout>
      {!transcriptHistoryLoading &&
      !studentCELoading &&
      oktaProfile?.profile?.studentID != null ? (
        <ProcessMessages
          transcriptHistory={transcriptHistory}
          courseHistory={courseHistory}
          studentCE={studentCE}
        />
      ) : (
        <></>
      )}
      <Breadcrumbs />
      <div className="container dashboard">
        <div className="intro-content-wrapper">
          <AuthDetails page="dashboard" />
          <p className="page-intro-text lg:w-1/2">
            Tour your dynamic, personalized learning hub with a dashboard view
            of your learning journey and resources you’ve curated from the
            nation’s leading academics, researchers, and thought leaders.
          </p>
        </div>
        <div className="card-layout">
          {/* Show DesignationsModule block here if not recertified */}
          {(recertStatus !== 'recertified' &&  recertStatus !== 'firstyear' && recertStatus !== 'noparticipation') && (
            <>
              {studentCE && Object.keys(studentCE).length > 0 && (
                <DesignationsModule
                  completedDesignations={completedDesignations}
                  studentCE={studentCE}
                />
              )}
              {studentCE && Object.keys(studentCE).length === 0 && (
                <DesignationsModuleNone />
              )}
            </>
          )}
          {!isLoadingFlags &&
            studentFlags?.khp_access === 'Y' &&
            !studentCELoading && <KnowledgeHubPlus studentCE={studentCE} />}
          {!courseHistoryLoading && !studentCELoading ? (
            <>
              {designations.length > 0 || jitDesignations.length > 0 ? (
                <>
                  {designations
                    .sort((a, b) => (a > b ? 1 : -1))
                    .map((designation) => {
                      return (
                        <LearningJourneyModule
                          key={designation}
                          designation={designation}
                          courseData={courseHistory}
                        />
                      );
                    })}
                  {jitDesignations
                    .sort((a, b) => (a > b ? 1 : -1))
                    .map((designation) => {
                      return (
                        <LearningJourneyJIT
                          key={designation}
                          designation={designation}
                          courseData={courseHistory}
                        />
                      );
                    })}
                </>
              ) : (
                <LearningJourneyNoEnrollment />
              )}
              {/* Show DesignationsModule block here if recertified */}
              {(recertStatus === 'recertified' ||  recertStatus === 'firstyear' || recertStatus === 'noparticipation') && (
                <>
                  {studentCE && Object.keys(studentCE).length > 0 && (
                    <DesignationsModule
                      completedDesignations={completedDesignations}
                      studentCE={studentCE}
                    />
                  )}
                  {studentCE && Object.keys(studentCE).length === 0 && (
                    <DesignationsModuleNone />
                  )}
                </>
              )}
            </>
          ) : (
            <Timer />
          )}

          {oktaProfile && <StaticSavedResources />}

          <div className="column-layout w-full sm-gap mobile-vert-gap">
            <div className="col col--two-thirds">
              <PromotionalCalloutModule />
            </div>

            <div className="col col--third">
              <QuickLinksModule />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
