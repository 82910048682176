import { useLocation, Redirect } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import { Layout } from '../components/Layout';
import { LoginForm } from '../components/login/LoginForm';

import { SiteNotificationBanner } from '../components/subcomponents/SiteNotificationBanner';
import { BrowserWarningBanner } from '../components/subcomponents/BrowserWarningBanner';
import { ReflexBlueLogoImage } from '../components/subcomponents/Icon';

export function LoginPage() {
  const { authState } = useOktaAuth();
  const location = useLocation();

 return (
    <>
      {authState && authState.isAuthenticated ? (
        <Redirect to={{ pathname: '/dashboard', state: { from: location } }} />
      ) : (
        <Layout>
      <BrowserWarningBanner />
      <div className="notif-banner">
        <SiteNotificationBanner />
      </div>
          <div className="column-layout container portal-login-page">
            <div className="col">
              <div className="logo">
                <ReflexBlueLogoImage className="w-60 lg:w-80" />
              </div>
              <div className="login-desc">
                <h1>Log in to My Learning Hub</h1>
                <p className="p3">Access education and insights including:</p>
                <ul className="bullets">
                  <li>Your student profile and learning journey</li>
                  <li>Course history and transcripts</li>
                  <li>Recertification and CE reporting</li>
                  <li>Messages tailored to your status and preferences</li>
                  <li>A library of valuable resources</li>
                </ul>
              </div>
            </div>
            <div className="col">
              <LoginForm />
            </div>
          </div>
        </Layout>
      )}
    </>
  );
}
