import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';
import ReadStorage from '../../components/storage/ReadStorage';
import ClearStorage from '../../components/storage/ClearStorage';

export function LoginCallback() {
  const { oktaAuth } = useOktaAuth();
  const { ClearSingleItem } = ClearStorage();
  const navigate = useHistory();

  if (oktaAuth.isLoginRedirect()) {
    // Parse token from redirect url
    oktaAuth.token.parseFromUrl().then((data) => {
      const { accessToken, idToken } = data.tokens;
      // Store parsed token in Token Manager
      oktaAuth.tokenManager.add('idToken', idToken);
      oktaAuth.tokenManager.add('accessToken', accessToken);
      // Navigate to the destination.
      if (ReadStorage('loginDestination') !== undefined) {
        navigate.push(ReadStorage('loginDestination'));
        // Clear the destination variable.
        ClearSingleItem('loginDestination');
      } else {
        navigate.push('/dashboard');
      }
    });
  }
}
