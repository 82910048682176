import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import Divider from '@mui/material/Divider';
import { OktaSSOLink } from '../../_okta/config/OktaSSO';

import { getBadgeImage } from '../../utils/helper';

import { CircularProgressBar } from '../subcomponents/CircularProgressBar';
import { WarningIcon, InfoIcon } from '../subcomponents/Icon';
import Accordion from '../subcomponents/Accordion';
import {
  //  getRecertStatus,
  //  getRecertDisplay,
  getRecertStatusRevised,
  getRecertDisplayRevised,
  compliantStatuses,
} from '../../pages/DesignationsCertifications';
import dayjs from 'dayjs';
import classOptions from '../../data/classOptions.json';
export function DesignationsModule({ completedDesignations, studentCE }) {
  //  const status = getRecertStatus(studentCE);
  const status = getRecertStatusRevised(studentCE);
  //  console.log('Status is ' + status);
  const recertCycle = !compliantStatuses.includes(status);
  // dates hardcoded for launch
  const currentYear = parseInt(dayjs().format('YYYY'));
  const previousYear = currentYear - 1;
  const currentMonth = parseInt(dayjs().format('MM'));

  //  console.log(currentYear, previousYear, currentMonth);
  let recertDate = !compliantStatuses.includes(status)
    ? `12/31/${previousYear}`
    : `12/31/${currentYear}`;
  if (currentMonth >= 9) {
    recertDate = `12/31/${currentYear}`;
  }
  //  const ceDueDate = parseInt(dayjs(studentCE.tac_ce_end_dt).format('YYYY'));

  //  const statusDisplay = getRecertDisplay(status);
  const statusDisplay = getRecertDisplayRevised(studentCE);
  const designations = classOptions.designations;

  const ceReportRequired = studentCE?.tac_ce_req_flag?.trim() === 'Y';
  const questionnaireCompleted = studentCE?.tac_question_stat?.trim() === 'C';
  const paymementCompleted = studentCE?.tac_ques_paym_bt?.toUpperCase() === 'N' && studentCE?.tac_ques_paym_ms.toUpperCase() !== 'DUE NOW!';
  const prpReported = studentCE?.tac_ques_ce_bt?.toUpperCase() === 'N';
  
  const ssoAccredible = () =>
    window.open(process.env.REACT_APP_ACCREDIBLE_SSO_URL, '_blank');
  const getProgress = () => {
    let steps = studentCE?.tac_db_cmpl_step;

    const title = (
      <>
        <span className="num">{steps}</span> of <span className="num">3</span>
        <br />
        steps completed
      </>
    );

    return [(steps / 3) * 100, title];
  };

  const [percentage, title] = getProgress();

  return (
    <div className="card drop-box padding--sm w-full designations-module">
      <div className="flex card-header">
        <div className="title3">
          <Link to="/designations-certifications">
            My Designations & Certifications
          </Link>
        </div>
        <div className="status mobile">
          <p className="recert-status-wrapper p3">
            <span className="font-semibold">Status:</span> {statusDisplay}
          </p>

          {(status === 'pastdue' || status === 'immediateaction') && (
              <p className="p3 recert-notif">
                <span>
                  <WarningIcon />
                </span>
                <span>
                  Past Due - Your recertification was due by {recertDate}
                </span>
              </p>
            )}
          {status === 'firstyear' && (
            <p className="p3 recert-notif recert-info">
              <span>
                <InfoIcon />
              </span>
              <span>
                The first Recertification cycle begins on January 1, {studentCE?.tac_first_ce_yr}. 
                The annual fee is paid in advance of the recertification year. Payment of your first recertification fee
                is due no later than 12/31/{studentCE?.tac_first_fee_yr}.
              </span>
            </p>
          )}
        </div>
        {(studentCE?.tac_db_ce_bt?.trim().toUpperCase() === 'Y' && (!questionnaireCompleted || !paymementCompleted)) && (
          <Link
            to="/designations-certifications/recertification/ce-reporting"
            className="btn">
            Report PRP CE
          </Link>
        )}
      </div>

      <Divider className="top-divider" />

      <div className="content-wrapper">
        <div className="completion-img desktop">
            <div className="no-enrollment-img">
              <img alt="" src="/images/no-enrollment.svg" />
            </div>
        </div>
        <div className="status desktop">
          <p className="recert-status-wrapper p3">
            <span className="font-semibold">Status:</span> {statusDisplay}
          </p>
          {studentCE.tac_db_message && (
            <p className="p3 recert-notif">
              <span>
                <WarningIcon />
              </span>
              <span>{studentCE.tac_db_message}</span>
            </p>
          )}
          {(studentCE.tac_db_paym_msg || studentCE.tac_db_ce_message) && (
            <ul className="bullets p3">
              {studentCE.tac_db_paym_msg && (
                <li>{studentCE.tac_db_paym_msg}</li>
              )}
              {studentCE.tac_db_ce_message && (
                <li>{studentCE.tac_db_ce_message}</li>
              )}
            </ul>
          )}

          {studentCE?.tac_db_rec_bt?.trim().toUpperCase() === 'Y' && (
            <>
              {ceReportRequired ? (
                <Link
                  to="/designations-certifications/recertification"
                  className="btn">
                  {studentCE.tac_db_status?.toLowerCase() === 'recertified'
                    ? 'View'
                    : 'Recertify Now'}
                </Link>
              ) : questionnaireCompleted ? (
                <OktaSSOLink classes="btn" rcrt="1">
                  Recertify Now
                </OktaSSOLink>
              ) : (
                <Link
                  to="/designations-certifications/recertification/ethics-questionnaire"
                  className="btn">
                  Recertify Now
                </Link>
              )}
            </>
          )}
            {studentCE.tac_db_status?.toLowerCase() === 'recertified' && (
                <p>
                    Congratulations on meeting all the Recertification requirements! Click {
                    <Link
                      to="/designations-certifications/recertification/ethics-questionnaire"
                      className="underline-text reflexBlue">
                      here
                    </Link> } to access detailed information about your recertification process.     
                </p>
            )}
        </div>
        <div className="status mobile">
          {(studentCE.tac_db_paym_msg || studentCE.tac_db_ce_message) && (
            <ul className="bullets p3">
              {studentCE.tac_db_paym_msg && (
                <li>{studentCE.tac_db_paym_msg}</li>
              )}
              {studentCE.tac_db_ce_message && (
                <li>{studentCE.tac_db_ce_message}</li>
              )}
            </ul>
          )}

          {recertCycle && (
            <>
              {ceReportRequired ? (
                <Link
                  to="/designations-certifications/recertification"
                  className="btn">
                  Recertify Now
                </Link>
              ) : questionnaireCompleted ? (
                <OktaSSOLink classes="btn" rcrt="1">
                  Recertify Now
                </OktaSSOLink>
              ) : (
                <Link
                  to="/designations-certifications/recertification/ethics-questionnaire"
                  className="btn">
                  Recertify Now
                </Link>
              )}
            </>
          )}
          {studentCE.tac_db_status?.toLowerCase() === 'recertified' && (
                <p>
                    Congratulations on meeting all the Recertification requirements! Click {
                    <Link
                      to="/designations-certifications/recertification/ethics-questionnaire"
                      className="underline-text reflexBlue">
                      here
                    </Link> } to access detailed information about your recertification process.     
                </p>
            )}
        </div>
        <div className="actionItems">
            <Divider orientation="vertical" flexItem className="desktop"/>
            <div className="list">
                <div className="small-headline gray">Action Items</div>
                <ol>
                    <li>Ethics Questionnaire<br/> <span className={`uppercase font-semibold ${studentCE?.tac_ques_ethics_bt?.toUpperCase() === 'Y'?'badStanding':'goodStanding'}`}>{studentCE?.tac_ques_ethics_ms}</span></li>
                    <li>Submit Payment<br/> <span className={`uppercase font-semibold ${!paymementCompleted?'badStanding':'goodStanding'}`}>{studentCE?.tac_ques_paym_ms}</span></li>
                    <li>Report CE<br/>  <span className={`uppercase font-semibold ${!prpReported?'badStanding':'goodStanding'}`}>{studentCE?.tac_ques_ce_ms}</span></li>
                </ol>
                {studentCE?.tac_ques_ethics_bt?.toUpperCase() === 'Y' && !questionnaireCompleted && (
                    <Link
                      to="/designations-certifications/recertification/ethics-questionnaire"
                      className="btn">
                      Step 1: Ethics Questionnaire
                    </Link>
                )}
                {  questionnaireCompleted && !paymementCompleted && (
                    <OktaSSOLink classes="btn" rcrt="1" sameTab={true}>
                      Step 2: Submit Payment
                    </OktaSSOLink>
                )}
                {questionnaireCompleted && paymementCompleted && !prpReported && (
                    <Link
                      to="/designations-certifications/recertification/ce-reporting"
                      className="btn">
                      Step 3: Report PRP CE
                    </Link>
                )}
            </div>
        </div>
      </div>
      {completedDesignations.length > 0 && (
        <>
          <Divider className="bottom-divider" />

          <div className="badges desktop">
            <p className="title4">Utilize Your Digital Badges</p>

            <div className="designation-icons">
              {completedDesignations.forEach((designation) => {
                if (designation !== 'CFP') {
                  const badgeImage = getBadgeImage(designation);

                  return (
                    <div key={designation} className="designation-icon">
                      {badgeImage && (
                        <img
                          src={badgeImage}
                          alt={`${designation} Badge`}
                          className="badge-image"
                          onClick={ssoAccredible}
                        />
                      )}
                      {designations[designation.toLowerCase()] ? (
                        <p className="p3">
                          {parse(
                            designations[designation.toLowerCase()].shortName,
                          )}{' '}
                          -{parse(designations[designation.toLowerCase()].name)}
                        </p>
                      ) : (
                        <p className="p3">{designation}</p>
                      )}
                    </div>
                  );
                }
              })}
            </div>
          </div>

          <div className="badges mobile">
            <Accordion
              title=<p className="title4">Utilize Your Digital Badges</p>
              startOpen={true}
              id="digitalbadges">
              <p className="p4 font-semibold">
                Click the &ndash; icon to hide your badges
              </p>
              <div className="designation-icons">
                {completedDesignations.forEach((designation) => {
                  if (designation !== 'CFP') {
                    const badgeImage = getBadgeImage(designation);

                    return (
                      <div key={designation} className="designation-icon">
                        {badgeImage && (
                          <img
                            src={badgeImage}
                            alt={`${designation} Badge`}
                            className="badge-image"
                          />
                        )}
                        {designations[designation.toLowerCase()] ? (
                          <p className="p3">
                            {parse(
                              designations[designation.toLowerCase()].shortName,
                            )}{' '}
                            -
                            {parse(
                              designations[designation.toLowerCase()].name,
                            )}
                          </p>
                        ) : (
                          <p className="p3">{designation}</p>
                        )}
                      </div>
                    );
                  }
                })}
              </div>
            </Accordion>
          </div>
        </>
      )}
    </div>
  );
}

export function DesignationsModuleNone() {
  return (
    <div className="card drop-box padding--sm w-full designations-module no-designations">
      <div className="flex card-header">
        <div className="title3">My Designations & Certifications</div>
      </div>

      <Divider className="top-divider" />

      <div className="content-wrapper">
        Your lifelong learning journey with The College is just beginning. If
        you’re in the middle of a program, keep going! If you want to start a
        new path, discover what learning at The College can mean for you. We’re
        here to help!
      </div>
    </div>
  );
}
