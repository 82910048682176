import { useState, useRef } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Link } from 'react-router-dom';

import selectOptions from '../../data/selectOptions.json';
import useTacfsService from '../../utils/tacfs/useTacfsService';
import CompanyAffiliationFormikField from '../subcomponents/companyFields/CompanyAffiliationFormikField';
import CompanyCustomFormikFields from '../subcomponents/companyFields/CompanyCustomFormikFields';
import { OtherCompanyIds as ocId } from '../../data/Constants';
import { Formik } from 'formik';
import {
  FormikSelect,
  FormikText,
  FormikCheckbox,
} from '../subcomponents/InputField';
import * as Yup from 'yup';
import Modal from '../subcomponents/Modal';
import Timer from '../subcomponents/Timer';

export function BasicCreateAccountForm({
  setCompleted,
  userData,
  company,
  showDubModal = true,
}) {
  const formikRef = useRef();
  const [error, setError] = useState(null);
  const { load, save } = useTacfsService();
  const stateOptions = selectOptions.state;
  const phoneOptions = selectOptions.phoneType;
  const [duplicateAcctFound, setDuplicateAcctFound] = useState(false);

  const { isLoading: companiesLoading, data: companies } = useQuery(
    ['companies'],
    () => load('companies'),
  );

  const { isLoading: submitting, mutate: generateAccount } = useMutation(
    (values) => save('createStudent', values),
  );

  const { isLoading: ssnChecksubmitting, mutate: checkStudentSsnExists } =
    useMutation((values) => save('checkExistingStudent', values));

  const { isLoading: emailChecksubmitting, mutate: checkStudentEmailExists } =
    useMutation((values) => save('checkExistingStudent', values));

  const zipRegExp = /^\d{5}$/;
  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

  Yup.addMethod(Yup.string, 'checkStudentSsn', function (message) {
    return this.test('checkStudentSsn', message, function (value) {
      const { path } = this;
      return new Promise((resolve, reject) => {
        checkStudentSsnExists(
          {
            ssn: value,
          },
          {
            onError: (res) => {
              console.log(res);
              resolve(true);
            },
            onSuccess: (data) => {
              if (data.status === 'N') {
                resolve(true);
              } else {
                setDuplicateAcctFound(true);
                reject(this.createError({ path, message }));
              }
            },
          },
        );
      });
    });
  });

  Yup.addMethod(Yup.string, 'checkStudentEmail', function (message) {
    return this.test('checkStudentEmail', message, function (value) {
      const { path } = this;
      return new Promise((resolve, reject) => {
        checkStudentEmailExists(
          {
            email: value,
          },
          {
            onError: (res) => {
              console.log(res);
              resolve(true);
            },
            onSuccess: (data) => {
              if (data.status === 'N') {
                resolve(true);
              } else {
                setDuplicateAcctFound(true);
                reject(this.createError({ path, message }));
              }
            },
          },
        );
      });
    });
  });

  const customValidationSchema = Yup.object().shape({
    email: Yup.string().checkStudentEmail('This email has been taken.'),
    ssnOptOut: Yup.bool(),
    ssn: Yup.string().when(['ssnOptOut'], (ssnOptOut, schema) => {
      if (ssnOptOut && ssnOptOut[0] === false)
        return schema.checkStudentSsn('Social Security number already exists.');
    }),
  });

  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Must be a valid email')
      .required('Please enter a valid email'),
    confirmEmail: Yup.string().when(['email'], (email, schema) => {
      return schema
        .oneOf([email[0], null], "Email fields don't match!")
        .required('Field required');
    }),
    secondEmail: Yup.string()
      .email('Must be a valid email')
      .required('Please enter a valid backup email')
      .when(['email'], (email, schema) => {
        return schema
          .notOneOf(
            [email[0]],
            'Backup email cannot be the same as your primary email address.',
          )
          .required('Field required');
      }),
    confirmSecondEmail: Yup.string().when(
      ['secondEmail'],
      (secondEmail, schema) => {
        return schema
          .oneOf([secondEmail[0], null], "Backup email fields don't match!")
          .required('Field required');
      },
    ),
    firstName: Yup.string().required('Required Field*'),
    lastName: Yup.string().required('Required Field*'),
    ssnOptOut: Yup.bool(),
    ssn: Yup.string().when(['ssnOptOut'], (ssnOptOut, schema) => {
      if (ssnOptOut && ssnOptOut[0] === false)
        return schema
          .required('Required Field*')
          .matches(
            /^(?!666|000|111|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/,
            'Invalid SSN format.',
          );
    }),
    address: Yup.string().required('Required Field*'),
    city: Yup.string().required('Required Field*'),
    state: Yup.string().required('Required Field*'),
    zip: Yup.string()
      .matches(zipRegExp, 'ZIP Code is not valid')
      .required('Required Field*'),
    phone: Yup.string()
      .matches(phoneRegExp, '*Invalid phone format')
      .required('Required Field*'),
    phoneType: Yup.string().required('Required Field*'),
    busAddress: Yup.string().required('Required Field*'),
    busCity: Yup.string().required('Required Field*'),
    busState: Yup.string().required('Required Field*'),
    busZip: Yup.string()
      .matches(zipRegExp, 'ZIP Code is not valid')
      .required('Required Field*'),
    busPhone: Yup.string()
      .matches(phoneRegExp, '*Invalid phone format')
      .required('Required Field*'),
    busPhoneType: Yup.string().required('Required Field*'),
    company1: Yup.string().required('Please select a Company'),
    company2: Yup.string().when(['company1'], (company1, schema) => {
      if (
        [
          ocId._INDEPENDENT,
          ocId._OTHER,
          ocId._BROKER_NOT_LISTED,
          ocId._ATTORNEY,
          ocId._CPA,
        ].includes(company1)
      ) {
        return schema.required('Please select a Broker/Dealer');
      }
    }),
    userCompanyName: Yup.string().when(['company1'], (company1, schema) => {
      if (
        [
          ocId._INDEPENDENT,
          ocId._OTHER,
          ocId._NONPROFIT,
          ocId._BROKER_NOT_LISTED,
          ocId._ATTORNEY,
          ocId._CPA,
        ].includes(company1)
      ) {
        return schema.required('Please enter a Company Name');
      }
    }),
  });

  // There some validation we only want to run
  // when the submition hanlder is clicked as it
  // calls api endpoints.
  const validation = async (values) => {
    return customValidationSchema
      .validate(values, { abortEarly: false })
      .then(function () {
        return true;
      })
      .catch(function (err) {
        err.inner.forEach((error) => {
          // set the formik error.
          formikRef.current.setFieldError(error.path, error.errors);
        });
        return false;
      });
  };

  const formSubmit = async (values) => {
    // Check the custom form validation
    // if schema was passed in.
    const valid = await validation(values);
    // Make sure we passed the validation check.
    if (valid) {
      // Do proper assignment on values now that we're validated!
      values.company = values?.company2 ? values.company2 : values.company1;
      values.companyName = values?.userCompanyName
        ? values.userCompanyName
        : values.companyName;

      const data = {
        provisioned: userData || false,
        last_name: values.lastName,
        first_name: values.firstName,
        middle_name: '',
        prefix: values.prefix,
        country: 'USA',
        ssn: values.ssn,
        enrollment_source: userData && userData?.profile?.nwmUuid ? 'NM' : 'WE',
        nwm_uuid: userData ? userData?.profile?.nwmUuid : '',
        home_information: {
          address1: values.address,
          address2: values.address2,
          address3: '',
          city: values.city,
          state: values.state,
          postal: values.zip,
          phone: values.phone,
          phone_type: values.phoneType,
          email: values.email,
          secondEmail: values.secondEmail,
        },
        business_information: {
          address1: values.busAddress,
          address2: values.busAddress2,
          address3: '',
          city: values.busCity,
          state: values.busState,
          postal: values.busZip,
          phone: values.busPhone,
          phone_type: values.busPhoneType,
          email: values.busEmail,
        },
        company_affiliation: {
          company_id: values.company,
          company_name: values.companyName,
          affiliation_id: values.agent_id,
          secondary_affiliation_id: '',
          role: values.agent_role,
          region: values.region,
          lsp_cd: '',
          ntid: values.ntid.substring(0, 15),
          independent: [ocId._INDEPENDENT, ocId._OTHER].includes(
            values.company1,
          )
            ? 'Y'
            : 'N',
        },
      };

      if (!submitting && !ssnChecksubmitting && !emailChecksubmitting) {
        // We want to register the course
        generateAccount(data, {
          onError: (res) => setError(res),
          onSuccess: (data) => {
            if (data.student_id) setCompleted(true);
            else setError(`There was an error with the form submission.`);
          },
        });
      }
    }
  };

  if (companiesLoading) {
    return <Timer />;
  }

  return (
    <>
      {duplicateAcctFound && showDubModal && (
        <Modal
          showLink={false}
          showNow={true}
          noClose={true}
          title="Account already exists"
          linkClasses="btn secondary">
          <div className="container--sm text-center flex-column gap-8">
            <p className="title2 w-full">
              You already have an account with The College!
            </p>
            <p className="w-full">
              Your email address and/or Social Security Number is already
              associated with a "My Learning Hub" account. Please log in before
              completing this application.
            </p>
            <Link to="/" className="btn mx-auto">
              Log in Now
            </Link>
            <p className="p4 w-full">
              If you believe you have received this message in error, contact
              Admissions at <a href="tel:888-263-7265">888-263-7265</a>.
            </p>
          </div>
        </Modal>
      )}
      {(!duplicateAcctFound || showDubModal === false) && (
        <Formik
          initialValues={{
            email: userData?.profile?.email ?? '',
            confirmEmail: userData?.profile?.email ?? '',
            secondEmail: '',
            confirmSecondEmail: '',
            firstName: userData?.profile?.firstName ?? '',
            lastName: userData?.profile?.lastName ?? '',
            prefix: '',
            ssn: '',
            ssnOptOut: false,
            address: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            phone: '',
            phoneType: '',
            busAddress: '',
            busAddress2: '',
            busCity: '',
            busState: '',
            busZip: '',
            busPhone: '',
            busPhoneType: '',
            company1: company ?? '',
            company: '',
            brokerDealer: '',
            companyName: '',
            agent_id: '',
            agent_role: '',
            region: '',
            ntid: '',
          }}
          enableReinitialize={false}
          validationSchema={ValidationSchema}
          innerRef={formikRef}
          onSubmit={(values) => {
            formSubmit(values);
          }}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldError,
          }) => (
            <form onSubmit={handleSubmit} id="createAccount">
              <div className="column-layout">
                <div className="col">
                  <div className="field">
                    <FormikText
                      type="email"
                      id="email"
                      name="email"
                      placeholder="* Email Address"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="field">
                    <FormikText
                      type="email"
                      id="confirmEmail"
                      name="confirmEmail"
                      placeholder="* Confirm Email Address"
                      value={values.confirmEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>
              <div className="column-layout">
                <div className="col">
                  <div className="field">
                    <FormikText
                      type="email"
                      id="secondEmail"
                      name="secondEmail"
                      placeholder="* Backup Email Address"
                      value={values.secondEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="field">
                    <FormikText
                      type="email"
                      id="confirmSecondEmail"
                      name="confirmSecondEmail"
                      placeholder="* Confirm Backup Email Address"
                      value={values.confirmSecondEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>

              <div className="column-layout">
                <div className="col--quarter">
                  <div className="field">
                    <FormikSelect
                      id="prefix"
                      name="prefix"
                      value={values.prefix}
                      onChange={handleChange}
                      className={
                        touched.prefix && errors.prefix ? 'error' : null
                      }>
                      <option value="">Prefix</option>
                      <option value="Mr">Mr.</option>
                      <option value="Ms">Ms.</option>
                      <option value="Mrs">Mrs.</option>
                    </FormikSelect>
                  </div>
                </div>
                <div className="col--third">
                  <div className="field">
                    <FormikText
                      type="text"
                      id="firstName"
                      name="firstName"
                      placeholder="* First Name"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={userData?.profile?.firstName ? true : false}
                    />
                  </div>
                </div>
                <div className="col--third">
                  <div className="field">
                    <FormikText
                      type="text"
                      id="lastName"
                      name="lastName"
                      placeholder="* Last Name"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={userData?.profile?.lastName ? true : false}
                    />
                  </div>
                </div>
              </div>
              <div className="column-layout">
                <div className="col">
                  <div className="field">
                    <FormikText
                      type="text"
                      id="ssn"
                      name="ssn"
                      placeholder="* SSN"
                      disabled={values.ssnOptOut}
                      value={values.ssn}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <ul className="checkboxes">
                    <li>
                      <FormikCheckbox
                        name="ssnOptOut"
                        id="ssnOptOut"
                        checked={values.ssnOptOut}
                        onChange={(e) => {
                          setFieldValue('ssn', '');
                          setFieldError('ssn', '');
                          handleChange(e);
                        }}>
                        I choose not to supply my Social Security Number. I
                        understand that by doing so, I will be ineligible to
                        claim tax credit for course tuition.
                      </FormikCheckbox>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="column-layout">
                <div className="col">
                  <div className="field">
                    <FormikText
                      type="text"
                      id="address"
                      name="address"
                      placeholder="* Personal Street Address 1"
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="field">
                    <FormikText
                      type="text"
                      id="address2"
                      name="address2"
                      placeholder="Personal Street Address 2"
                      value={values.address2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>

              <div className="column-layout">
                <div className="col--third">
                  <div className="field">
                    <FormikText
                      type="text"
                      id="city"
                      name="city"
                      placeholder="* City"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="col--third">
                  <div className="field">
                    <FormikSelect
                      id="state"
                      name="state"
                      value={values.state}
                      onChange={handleChange}>
                      {stateOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </FormikSelect>
                  </div>
                </div>
                <div className="col--quarter">
                  <div className="field">
                    <FormikText
                      type="text"
                      id="zip"
                      name="zip"
                      placeholder="* Zip Code"
                      value={values.zip}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>
              <div className="column-layout">
                <div className="col">
                  <div className="field">
                    <FormikText
                      type="text"
                      id="phone"
                      name="phone"
                      placeholder="*Personal Phone Number"
                      value={values.phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="field">
                    <FormikSelect
                      id="phoneType"
                      name="phoneType"
                      value={values.phoneType}
                      onChange={handleChange}>
                      {phoneOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </FormikSelect>
                  </div>
                </div>
              </div>
              <CompanyAffiliationFormikField
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                companies={companies}
                companiesLoading={companiesLoading}
                disabled={company ? true : false}
              />
              <CompanyCustomFormikFields
                values={values}
                handleChange={handleChange}
              />
              <div className="column-layout">
                <div className="col">
                  <div className="field">
                    <FormikText
                      type="text"
                      id="busAddress"
                      name="busAddress"
                      placeholder="* Business Street Address 1"
                      value={values.busAddress}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="field">
                    <FormikText
                      type="text"
                      id="busAddress2"
                      name="busAddress2"
                      placeholder="Business Street Address 2"
                      value={values.busAddress2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>

              <div className="column-layout">
                <div className="col--third">
                  <div className="field">
                    <FormikText
                      type="text"
                      id="busCity"
                      name="busCity"
                      placeholder="* Business City"
                      value={values.busCity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="col--third">
                  <div className="field">
                    <FormikSelect
                      id="busState"
                      name="busState"
                      value={values.busState}
                      onChange={handleChange}>
                      {stateOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </FormikSelect>
                  </div>
                </div>
                <div className="col--quarter">
                  <div className="field">
                    <FormikText
                      type="text"
                      id="busZip"
                      name="busZip"
                      placeholder="* Zip Code"
                      value={values.busZip}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>
              <div className="column-layout">
                <div className="col">
                  <div className="field">
                    <FormikText
                      type="email"
                      id="busEmail"
                      name="busEmail"
                      placeholder="* Business Email Address"
                      value={values.busEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>

              <div className="column-layout">
                <div className="col">
                  <div className="field">
                    <FormikText
                      type="text"
                      id="busPhone"
                      name="busPhone"
                      placeholder="*Business Phone Number"
                      value={values.busPhone}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="field">
                    <FormikSelect
                      id="busPhoneType"
                      name="busPhoneType"
                      value={values.busPhoneType}
                      onChange={handleChange}>
                      {phoneOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </FormikSelect>
                  </div>
                </div>
              </div>
              {submitting || ssnChecksubmitting || emailChecksubmitting ? (
                <button
                  type="submit"
                  form="createAccount"
                  className="btn col disabled"
                  disabled>
                  Submitting...
                </button>
              ) : (
                <button type="submit" form="createAccount" className="btn col">
                  Create Account
                </button>
              )}
              {error && <p className="red-text">{error}</p>}
            </form>
          )}
        </Formik>
      )}
    </>
  );
}
